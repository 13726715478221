import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public cart = [];
  public allProducts = [];
  public cartObservable = new BehaviorSubject(
    localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
  );
  constructor(firestore: AngularFirestore) {
    this.cart = localStorage.getItem('cart')
      ? JSON.parse(localStorage.getItem('cart'))
      : [];
    firestore
      .collection('products')
      .valueChanges()
      .subscribe((products: any) => {
        this.allProducts = products.filter(i => i.active);
      });
  }
  addProduct(id, count) {
    if (_.find(this.cart, { product_id: id })) {
      this.cart[_.findIndex(this.cart, { product_id: id })].count = count;
    } else {
      this.cart.push({ product_id: id, count });
    }
    setTimeout(() => {
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.cartObservable.next(this.cart);
    }, 300);
  }

  deleteProduct(id) {
    this.cart = this.cart.filter((p) => p.product_id !== id);
    setTimeout(() => {
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.cartObservable.next(this.cart);
    }, 300);
  }

  plusProduct(id) {
    if (_.find(this.cart, { product_id: id })) {
      this.cart[_.findIndex(this.cart, { product_id: id })].count =
        this.cart[_.findIndex(this.cart, { product_id: id })].count + 1;
      setTimeout(() => {
        localStorage.setItem('cart', JSON.stringify(this.cart));
        this.cartObservable.next(this.cart);
      }, 300);
    }
  }
  minusProduct(id) {
    if (_.find(this.cart, { product_id: id })) {
      if (this.cart[_.findIndex(this.cart, { product_id: id })].count === 1) {
        this.cart = this.cart.filter((p) => p.product_id !== id);
      } else {
        this.cart[_.findIndex(this.cart, { product_id: id })].count =
          this.cart[_.findIndex(this.cart, { product_id: id })].count - 1;
      }
      setTimeout(() => {
        localStorage.setItem('cart', JSON.stringify(this.cart));
        this.cartObservable.next(this.cart);
      }, 300);
    }
  }
  clearCart(){
    this.cart = [];
    setTimeout(() => {
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.cartObservable.next(this.cart);
    }, 300);
  }
}
