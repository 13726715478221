import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {Router} from '@angular/router';
import {CartService} from '../cart/cart.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() text: string;
  @Input() image?: string;
  constructor(public modalController: ModalController, private router: Router, private cartService: CartService) {}

  ngOnInit() {}

  async dismiss() {
    await this.router.navigate(['/tabs/menu']);
    await this.modalController.dismiss({
      dismissed: true,
    });
    localStorage.clear();
    this.cartService.clearCart();
  }
}
